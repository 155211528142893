import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext, WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import HomeViewWrapper from 'ToolboxComponents/webapp-light/pages/home-view/home-view.style';
import Prehome from 'ToolboxComponents/webapp-light/pages/home-view/components/prehome/prehome';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import Tags from 'ToolboxAssets/img/tags.png';

const HomeView = props => {
  const theme = useContext(ThemeContext).state;
  const [isMobile] = useState(isMobileView());
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();

  return (
    <HomeViewWrapper theme={theme}>
      <Prehome nbSecrets={props.nbSecrets}/>
      <div className='share'>
        <div className='container'>
          <div className='title'>
            <h2>Partagez vos secrets et retrouvez les ici</h2>
          </div>
          {!isMobile && <hr />}
          <p>
            Grâce à vos recommandations très personnelles, nous allons pouvoir offrir une expérience encore plus locale
            à tous les voyageurs et nouveaux arrivants qui souhaitent s'installer en Vaucluse.
            Vous pourrez alors retrouver alors vos secrets sur: <br></br> <a href={whiteLabel.webappLight.secretUrl}>{whiteLabel.webappLight.secretUrl}</a> 
          </p>
        </div>
      </div>
      <div className='local'>
        <div className='container'>
          <h2>Un secret local, c'est...</h2>
          <p>
            un conseil entre amis sur le plat inoubliable de tel restaurant ou le spot où l'on ne peut rater sa photo.
            C'est le moment, le lieu rare, quand l'instant, le savoir-faire ou encore le produit tutoient les anges.
            <br/><br/>
            Il est chuchoté par des locaux qui ont la chance de vivre ici toute l'année et parfois par des voyageurs
            ayant pris le temps de goûter à notre destination.
          </p>
          <img src={Tags}/>
        </div>
      </div>
    </HomeViewWrapper>
    
  );
};


export default HomeView;